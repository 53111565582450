@font-face {
    font-family: 'Outline';
    src: url('../assets/fonts/Inter-Bold.woff') format('woff'),
    url('../assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('../assets/fonts/Inter-Bold.svg#Inter-Bold') format('svg'),
    url('../assets/fonts/Inter-Bold.eot'),
    url('../assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
