.mat-button-toggle-group {
  border-radius: 0 !important;

  &--sm {

    .mat-button-toggle {

      &-label-content {
        font-size: .875rem;
        line-height: 2 !important;
        padding: 0 .5rem !important;
      }

    }

  }

}
