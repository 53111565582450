.c-card-stage-gate {
  background-color: $white;
  border: 1px solid $line-dark-color;
  @include media-breakpoint-up(xxl) {
    padding: 2.5rem;
  }
  @include media-breakpoint-down(xxl) {
    padding: 2rem;
  }
  &__header {
    display: flex;

    &__left {
      display: flex;
      margin-right: 2rem;
    }

    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 1.875rem;
    }

    .badge {
      display: block;
    }

  }

  &__body {
    margin-top: 2rem;
  }

  &__text {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
              line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__footer {
    //display: flex;
    margin-top: 2rem;
    @include media-breakpoint-between(xl, xxxl) {
      //flex-direction: column;
      margin-top: 1rem;
    }
    &__left {
      display: flex;
      align-items: center;
      // @include media-breakpoint-up(fullMax) {
      //   flex: 1 0 0;
      // }
      @include media-breakpoint-between(xl, xxxl) {
        margin-bottom: 1rem;
      }
    }

    &__right {
      // @include media-breakpoint-up(fullMax) {
      //   flex: 1 0 0;
      // }
      // @include media-breakpoint-down(fullMax) {
      //   flex-grow: 1;
      // }
      .btn {
        float: right;
      }
    }

  }

  &__state {
    display: flex;
    align-items: center;
    margin-right: .75rem;
    height: 1.875rem;
  }

  &__title {
    font-size: $h5-font-size;
    margin-bottom: 0;
  }

  &__more {
    font-size: $font-size-sm;
    margin-right: 2rem;
    white-space: nowrap;
    // @include media-breakpoint-up(fullMax) {
    //   margin-right: 2rem;
    // }
    cursor: pointer;
  }

}
