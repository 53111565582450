.l-viewer {

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    @include media-breakpoint-up(xl) {
      padding: 2.5rem 0;
    }
    @include media-breakpoint-up(full) {
      padding: 2.5rem 0;
    }

    &__left {
      display: flex;
      align-items: center;
      margin-right: 2rem;
    }

    &__right {
      display: flex;
      align-items: center;
      .btn:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &__title {
      color: $gray-500;
      margin-bottom: 0 !important;
      font-size: $h6-font-size;
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }
      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
      }
      @include media-breakpoint-up(full) {
        font-size: $h3-font-size;
      }
      span {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      &--active {
        color: $body-color;
      }
    }
  }
  &__nav {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2.75rem;
    &__right {
      display: flex;

      .mat-form-field:not(:last-child) {
        margin-right: .5rem;
      }
      .mat-form-field {
        .mat-form-field-wrapper {
          margin-bottom: 0 !important;
        }
      }

    }
  }
}
