.c-slide-view {

  &:hover {
    .c-slide-view__header {
      .text-outline {
        opacity: 1;
        color: $primary;
      }
    }
  }

  &__header {
    margin-bottom: -1.5rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: -3rem;
    }
    .text-outline {
      font-size: 5.25rem;
      line-height: 1;
      transition: color .2s ease-in-out, opacity .2s ease-in-out;
      @include media-breakpoint-up(xl) {
        font-size: 9rem;
      }
    }
  }

  &__body {
    position: relative;
  }

  &__nav {
    position: absolute;
    right: 0;
    top: -3.25rem;
    z-index: 2;
    display: flex;
    .btn {
      background-color: $body-bg;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }

  .swiper {
    --swiper-navigation-size: 2.5rem;
    padding-bottom: 1rem;

    &-scrollbar {
      border-radius: 0;
      background: $line-dark-color;
      &-drag {
        border-radius: 0;
        background: $primary;
        height: 3px;
        top: -2px;
      }
    }

    &-horizontal {
      & > .swiper-scrollbar {
        width: 100%;
        left: 0;
        height: 1px;
        bottom: 0;
      }
    }

  }

  // Slide LITE
  &__lite {
    .swiper {
      overflow: visible;

      &-slide {
        width: 23.5rem;
      }

    }
  }

}
