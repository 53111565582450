.c-nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  overflow-x: auto;

  &__bar {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1rem;
      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
      }
      @include media-breakpoint-up(xl) {
        margin-right: 2rem;
      }
    }
  }

  &__link {
    display: flex;
    padding: .375rem 0;
    font-size: $font-size-sm;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    background-color: inherit;
    border: none;
    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
    }
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $secondary;
      position: absolute;
      bottom: 0;
      transform: translateY(6px);
      transition: .3s;
    }
    &.active,
    &:hover {
      &:before {
        transform: translateY(0);
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: .38;
    }

  }

  &--sm {
    .c-nav-tabs__link {
      font-size: $font-size-sm;
    }
  }

}
