.l-login {
    height: 100vh;
    &-wrapper {
        height: 100%;
    }
    &-left,
    &-right {
        display: flex;
        // align-items: center;
        // justify-content: center;
        height: 100%;
        padding: $login-padding-y $login-padding-x;
        position: relative;
        @include media-breakpoint-only(xxl) {
            padding: $login-padding-lg-y $login-padding-lg-x;
        }
        @include media-breakpoint-down(xxl) {
            padding: $login-padding-sm-y $login-padding-sm-x;
        }
        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }
    &-left {
        color: $white;
        display: flex;
        flex-direction: column;
        &__cnt {
            position: relative;
            margin: auto 0;
        }
        .mat-display-3 {
            margin-bottom: 15px !important;
            @include media-breakpoint-between(xxxl, fullMax) {
                font-size: 40px !important;
            }
            @include media-breakpoint-down(xxxl) {
                font-size: 34px !important;
            }
        }
        h2 {
            @include media-breakpoint-down(xxxl) {
                font-size: 24px !important;
            }
        }
        .l-login {
            &__brand {
                // position: absolute;
                // top: $login-padding-y;
                // left: $login-padding-x;
                position: relative;
                margin-bottom: 1.5rem;
                img {
                    width: 56px;
                }
                @include media-breakpoint-down(xxxl) {
                    img {
                        width: 46px;
                    }
                }
                // @include media-breakpoint-only(xxl) {
                //     top: $login-padding-lg-y;
                //     left: $login-padding-lg-x;
                // }
                // @include media-breakpoint-down(xxl) {
                //     top: $login-padding-sm-y;
                //     left: $login-padding-sm-x;
                // }
            }
            &__logo {
                // position: absolute;
                // bottom: $login-padding-y;
                // left: $login-padding-x;
                position: relative;
                margin-top: 1.5rem;
                @include media-breakpoint-down(xxxl) {
                    img {
                        width: 90px;
                    }
                }
                // @include media-breakpoint-only(xxl) {
                //     bottom: $login-padding-lg-y;
                //     left: $login-padding-lg-x;
                // }
                // @include media-breakpoint-down(xxl) {
                //     bottom: $login-padding-sm-y;
                //     left: $login-padding-sm-x;
                // }
                // @include media-breakpoint-down(lg) {
                //     bottom: auto;
                //     left: auto;
                //     top: $login-padding-sm-y;
                //     right: $login-padding-sm-x;
                // }
            }
        }
    }
    &-right {
        justify-content: center;
        &__admin {
            & > div {
                display: flex;
            }
            position: absolute;
            top: $login-padding-y;
            right: $login-padding-x;
            font-size: 14px;
            .btn-link {
                padding: 0;
                margin-left: 5px;
                line-height: 1;
            }
            @include media-breakpoint-only(xxl) {
                top: $login-padding-lg-y;
                right: $login-padding-lg-x;
            }
            @include media-breakpoint-down(xxl) {
                top: $login-padding-sm-y;
                right: $login-padding-sm-x;
            }
        }
        h2 {
            margin-bottom: 24px;
            @include media-breakpoint-down(xxxl) {
                font-size: 28px !important;
            }
        }
        &__subtitle {
          font-family: $font-family-condensed;
          text-transform: uppercase;
          color: $secondary;
          @include media-breakpoint-down(xxxl) {
            font-size: $font-size-sm;
          }
        }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        img {
            opacity: .7;
        }
    }

    &__form {
      flex: 0 0 25rem;
      margin: auto 0;
    }
}
