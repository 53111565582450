.c-sidebar {
  height: 100%;
  background-color: $gray-200;
  padding: 1.5rem;

  @include media-breakpoint-up(xl) {
    padding: 2rem 2.5rem;
  }

  &__header {
    border-bottom: 1px solid $border-color;
    padding-bottom: .5rem;
  }

  &__body {
    margin-top: 2rem;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 0;
    // @include media-breakpoint-up(md) {
    //   font-size: $h4-font-size;
    // }
    // @include media-breakpoint-up(xl) {
    //   font-size: $h3-font-size;
    // }
  }

}
