.c-select-fields {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__state {
    padding: .375rem;
    background-color: $body-bg;
    border-radius: 2rem;
    .mat-slide-toggle {
      display: block;
    }
  }

  &__label {
    font-size: $font-size-sm;
    font-family: $font-family-condensed;
    text-align: center;
  }

}
