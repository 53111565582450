.c-filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-bottom: 1rem;
  // background-color: $body-bg;
  // position: sticky;
  // top: 3rem;
  // z-index: $zindex-sticky;
  // @include media-breakpoint-up(xl) {
  //   top: 3.5rem;
  // }
  .mat-form-field-wrapper {
    margin-bottom: 0;
  }

  &__left {
    display: none;
    align-items: center;
    flex-grow: 1;
    margin-right: 1rem;
    @include media-breakpoint-up(xl) {
      display: flex;
      margin-right: 2.5rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__forms {
    display: flex;
    flex-grow: 1;
    .mat-form-field {
      flex: 1 0 0%;
      width: 100%;
      max-width: 100%;
      .mat-form-field-infix {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

  &__form {

    &--sort {
      max-width: 10rem;
    }

  }

  &__actions {
    display: flex;
    margin-left: 1rem;
    .btn {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

}
