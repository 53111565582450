// .btn {
//     & + .btn {
//         margin-left: 0.625rem;
//     }
// }

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .icon {
    stroke: currentColor;
  }
  .ic-svg {
    fill: currentColor;
  }

  &-only {
    height: 2.5rem;
    width: 2.5rem;
    flex: 0 0 2.5rem;
    padding: 0;
    // &__sm {
    //   height: 2rem;
    //   width: 2rem;
    //   padding: 0;
    //   display: inline-flex;
    //   align-items: center;
    //   justify-content: center;
    // }
    &.notification {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 3px;
        right: 6px;
        width: .75rem;
        height: .75rem;
        background-color: $danger;
        border: 2px solid $dark;
        border-radius: 50%;
      }
    }

    &.btn-sm {
      height: 2rem;
      width: 2rem;
      flex: 0 0 2rem;
    }

    &.btn-lg {
      height: 3rem;
      width: 3rem;
      flex: 0 0 3rem;
    }

  }

  &-left {
    .icon,
    .ic-svg {
      margin: -0.25rem .375rem -0.25rem -.5rem;

    }
  }

  &-right {
    .icon,
    .ic-svg {
      order: 1;
      margin: -0.25rem -.5rem -0.25rem .375rem;
    }
  }

}

.btn-link {
  font-size: 0.875rem;
  text-decoration: underline;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    text-decoration: none;
  }
  &-sm {
    font-size: 0.75rem;
  }
}

.btn-outline-primary {
  &:hover {
      .ic-svg {
        fill: $white;
      }
    }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}
