.c-list-view {
  // cursor: pointer;
  margin-bottom: 2rem;

  &__item {
    background-color: $white;
    border: 1px solid rgba($black, .1);
    padding: 1rem;
    @include media-breakpoint-down(xxl) {
      padding: .5rem;
    }

    &:not(:first-child) {
      margin-top: .5rem;
    }

    &:hover {
      border-color: $black;
    }

    &__row {
      align-items: center;
      .btn-icon {
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        flex: 0 0 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-link {
        @include media-breakpoint-down(xxxl) {
          font-size: 0.75rem;
        }
      }
      &.link{
        cursor: pointer;
      }
    }

    &__col {
      min-width: 1px;

      // Col Tasks
      &--tasks {
        flex: 0 0 calc(var(--#{$variable-prefix}gutter-x) + 1.5rem);
      }

      // Col Status
      &--status {
        flex: 0 0 calc(var(--#{$variable-prefix}gutter-x) + 4.75rem);

        .badge {
          width: 100%;
        }

      }

      // Col title
      &--title {
        padding-left: 0;
      }

      // Col team
      &--team {
        flex: 0 0 calc(var(--#{$variable-prefix}gutter-x) + 9.75rem);

        .c-data-label {
          flex-direction: row;
          align-items: center;

          &__caption {
            margin-right: .5rem;
          }

        }

      }

    }

    &__actions {
      display: flex;
      padding-left: 2rem;
      .btn {
        &:not(:last-child) {
          margin-right: 1rem;
        }
        & + .btn--delete {
          margin-left: .5rem;
        }
      }
    }
  }

  .col {
    display: flex;
  }

  .mat-caption {
    margin-bottom: 0;
    line-height: 1.375;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
    @include media-breakpoint-between(xxl, full) {
      font-size: 0.813rem;
    }
    @include media-breakpoint-down(xxl) {
      font-size: 0.75rem;
    }
  }

  .state-badge {
    margin: auto 0;
  }

  .stage-text {
    margin: auto;
  }

}

