.c-legend {
  display: inline-flex;
  align-items: center;

  &__label {
    font-family: $font-family-condensed;
    margin-left: .5rem;
  }

  &__group {
    display: flex;

    &__item {
      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }

  }

}
