.c-add-single {
  display: flex;
  align-items: center;

  &__media {

    &__btn {
      border-style: dashed;
    }

  }

  &__data {
    margin-left: .75rem;
  }

  &:not(.c-add-single--avatar) {
    align-items: flex-start;
    .c-add-single {
      &__media {
        &__img {
          img {
            max-width: 16.5rem;
            height: auto;
          }
        }
      }
    }
  }

}
