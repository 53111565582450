@use "sass:math";

// Day column width
$gantt-day-column-width: 3.125rem;
$gantt-day-column-height: 4rem;
$gantt-bar-duration-height: 1.5rem;
$gantt-bar-work-height: .875rem;
$gantt-bar-eventDaty-width: .125rem;
$gantt-day-header-height: 1.5rem;

.bar_tooltip {
  white-space: pre-line;
}

.c-gantt {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $gray-400;

  &__day {
    flex-grow: 1;
    min-width: 1px;
    flex: 1 0 0%;
  }

  &__event-daylist {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    flex-wrap: nowrap;

    &:hover{

      .c-gantt__day_grid__list_item__bar {
        background-color: $white;
        border-right-color: $gray-100;
      }
    }

  }

  &__event {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 23rem;
    border-right: 1px solid $gray-200;

    &__header {
      height: $gantt-day-header-height;
      border-bottom: 1px solid $gray-200;
    }

  }

  &__event__list {

    &__item {
      display: flex;
      padding-top: .125rem;
      padding-bottom: .125rem;
      padding-right: 1rem;
      height: $gantt-day-column-height;
      border-bottom: 1px solid $gray-200;
    }

    .c-card-view-short {
      height: 100%;
      width: 100%;
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;
    }

  }

  &__day_grid {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    &__header {
      display: flex;
      flex-wrap: nowrap;

      &_item{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
        flex-grow: 1;
        height: $gantt-day-header-height;
        // width: $gantt-day-column-width;
        font-size: .625rem;
        color: $gray-500;
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        width: 100%;

        &.current {
          background-color: $primary;
          color: $white;
        }

      }



    }

    &__list {

      &_item {
        padding: 0;
        text-align: center;
        font-size: .625rem;
        // width: $gantt-day-column-width;
        height: $gantt-day-column-height;
        min-width: 2rem;
        flex-grow: 1;
        width: 100%;
        //height: 25px;
        //border-right: 1px solid $gray-200;

        &__bar {
          position: relative;
          height: $gantt-day-column-height;
          border-right: 1px solid $gray-200;
          border-bottom: 1px solid $gray-200;
          transition: background-color .3s ease-in-out, border-color .3s ease-in-out;

          &_row__weekend {
            background-color: #edeff2;
          }

          .bar_start{
            left: 25%;
          }

          .bar_start:after {
            content: "";
            position: absolute;
            left: 0;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 3px solid $black;
            clear: both;
            transform: rotate(270deg);
            bottom: -$gantt-bar-work-height;
          }

          .bar_end{
            right: 25%;
          }

          .bar_end:after {
            content: "";
            position: absolute;
            right: 0;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 3px solid $black;
            clear: both;
            transform: rotate(-270deg);
            bottom: -$gantt-bar-work-height;
          }

          &_work {
            position: absolute;
            height: $gantt-bar-work-height;
            top: math.div($gantt-day-column-height - $gantt-bar-work-height, 2);
            left: 0;
            right: -0.0625rem;
            z-index: 1;
          }

          &_eventdate {
            background-color: $black;
            position: absolute;
            width: $gantt-bar-eventDaty-width;
            height: $gantt-bar-work-height;
            top: math.div($gantt-day-column-height - $gantt-bar-work-height, 2);
            left: 50%;
            margin-left: -0.0625rem;
            z-index: 1;
          }

          &_eventdate:before {
            content: "";
            position: absolute;
            top: 100%;
            left: -1px;
            margin-top: 10px;
            height: .25rem;
            width: .25rem;
            border-radius: .25rem;
            background-color: $black;
          }

          &_duration {
            background-color: $gray-300;
            position: absolute;
            height: $gantt-bar-duration-height;
            top: math.div($gantt-day-column-height - $gantt-bar-duration-height, 2);
            left: 0;
            right: -0.0625rem;
            z-index: 0;
          }

        }
      }

    }

  }

}
