.c-point-state {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  border: 1px solid $primary;
  background-color: $white;
  transition: background-color .2s ease-in-out;

  &--must {
    background-color: $primary;
  }

  &--should {
    background-color: $gray-300;
  }

  // Sizes
  &--sm {
    height: .5rem;
    width: .5rem;
  }
}
