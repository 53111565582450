.l-admin {
    .l-main__header {
        flex-direction: column;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
        &__right {
            padding-bottom: 0;
            justify-content: flex-end;
        }
    }
    .c-accordion-item {
        border-top: none;
        border-bottom: 1px solid $gray-400;
    }
}