.mat-dialog {

  &-container {
    border-radius: 0 !important;
    padding: 2rem !important;
  }

  &-header {
    padding-bottom: 1.5rem !important;
    border-bottom: 1px solid $line-dark-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    @include font-size($h4-font-size !important);
    margin-bottom: 0 !important;
  }

  &-content {
    margin: 0 -2rem !important;
    padding: 1.5rem 2rem !important;
  }

  &-actions {
    padding-top: 1.5rem !important;
    margin-bottom: -1rem !important;
    border-top: 1px solid $line-dark-color;
    .btn {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

}

.custom-dialog-container .mat-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  box-shadow: none;
}
