:root {
  --header-height: 3rem;
  --content-spacer-x: 1.5rem;

  @include media-breakpoint-up(xl) {
    --header-height: 3.5rem;
    --main-header-height: 7.5rem;
    --content-spacer-x: 2.5rem;
  }

  @include media-breakpoint-up(full) {
    --content-spacer-x: 5rem;
    --main-header-height: 8.5rem;
  }

}
