.c-gate {
    text-align: center;
    padding: 0 !important;
    line-height: 1;
    &-bottom {
        position: relative;
        margin-top: -23px;
        background: $light;
        padding-top: 1.25rem;
        //overflow: hidden;
        &:before,
        &:after {
            content: "";
            width: 50%;
            height: 1px;
            position: absolute;
            border-top: 1px dotted $gray-400;
            top: 0;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
        p {
            text-decoration: underline;
            color: $gray-300;
            font-family: $font-family-condensed;
            margin-bottom: 0 !important;
        }
    }
    &-state {
        width: 2rem;
        height: 2rem;
        background-color: $light;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        .c-toggle-state {
            border-color: $gray-400;
        }
    }
    &-num {
        position: relative;
        //bottom: -20px;
        font-family: $font-family-outline;
        font-size: 70px;
        font-weight: bold;
        -webkit-text-stroke: 1px $gray-300;
        color: transparent;
        @include media-breakpoint-up(xxxl) {
            font-size: 88px;
            //bottom: -30px;
        }
    }
    &.active {
        p {
            text-decoration: none;
            color: $primary;
        }
        .c-gate-state {
            .c-toggle-state {
                border-color: $primary;
            }
        }
        .c-gate-num {
            -webkit-text-stroke: 1px $primary;
            color: $primary;
        }
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
    }
}

.nav-gate-item {
    &.completed {
        .c-gate-state {
            .c-toggle-state {
                border-color: $primary;
            }
        }
        .c-gate-bottom {
            p {
                color: $primary;
            }
            &:before,
            &:after {
                border-top-style: solid;
                border-top-color: $primary;
            }
        }
        & + .nav-gate-item {
            .c-gate-bottom {
                &:before {
                    border-top-style: solid;
                    border-top-color: $primary;
                }
            }
        }
    }
    &.inprogress {
        .c-gate-state {
            .c-toggle-state {
                border-color: $primary;
            }
        }
    }
}

.bg_grey {
    background-color: #e3e3e3;
    p {
        font-size: 12px;
    }
}

.bg_white {
    background-color: #fff;
    p {
        font-size: 12px;
    }
}

.badge-dark {
    background-color: #111;
    color: #fff;
    border-radius: 3px !important;
}

.bg_grey {
    background-color: #e3e3e3;
    p {
        font-size: 12px;
    }
}

.bg_white {
    background-color: #fff;
    p {
        font-size: 12px;
    }
}

.badge-dark {
    background-color: #111;
    color: #fff;
    border-radius: 3px !important;
}
