.stage-text {
    font-size: $stage-size;
    color: $stage-color;
    font-weight: $stage-weight;
    @include media-breakpoint-only(xxxl) {
        font-size: 18px;
    }
    @include media-breakpoint-down(xxxl) {
        font-size: 16px;
    }
}