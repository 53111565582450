.icon {
  fill: none;
  stroke: currentColor;
  stroke-width: $icon-border-width;
  stroke-linecap: round;
  stroke-linejoin: round;
  width: $icon-width;
  height: $icon-height;
  flex: 0 0 $icon-width;
}

// Size
@each $size, $value in $iconSize {
  .ic-#{$size} {
    height: ($value);
    width: ($value);
  }
}

// Color
@each $color, $value in $icons-colors {
  .ic-#{$color} {
    stroke: ($value);
  }
}

.ic-16 {
  stroke-width: .1563rem
}
