.mat-menu__custom {

  .mat-menu-item {
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    & > svg {

      &:first-child {
        margin-right: 0.75rem;
      }

    }

  }

}

