.l-panel {

  &__header {
    padding-bottom: .5rem;
    border-bottom: 1px solid $line-dark-color;
  }

  &__body {
    margin-top: 2rem;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

}
