.c-data-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.25;
  min-width: 1px;

  &__block {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.125rem;
    max-width: 100%;

    & > * {

      &:not(:first-child) {
        margin-left: .5rem;
      }

    }

    .badge {
      text-transform: uppercase;
    }

  }

  &__caption {
    font-size: $font-size-xs;
    color: $data-label-color;
  }

  &__actions {
    font-size: .75rem;
    line-height: 1.5;
    a {
      text-decoration: none;
      opacity: .5;
      &:hover {
        opacity: 1;
      }
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

  // Group
  &-group {
    display: flex;
    min-width: 1px;
    .c-data-label {
      margin-left: .75rem;
      min-width: 1px;
    }
  }

  // Size
  &--xs {
    line-height: 1.25;
    .c-data-label {
      &__caption,
      &__actions {
        font-size: .75rem;
      }
      &__text {
        font-size: $font-size-sm;
        min-width: 1px;
      }
    }
  }

  &--sm {
    line-height: 1.5;
    .c-data-label {
      &__caption,
      &__actions {
        font-size: .75rem;
      }
      &__text {
        line-height: 1.5;
        font-size: $font-size-sm;
        min-width: 1px;
      }
    }
  }

  // Truncate
  &--truncate {
    .c-data-label {
      &__caption,
      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }
  }

}
