.links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.nodes {

  .node__title {
    font-size: 14px;
  }
  .node circle.danger {
    stroke: #dc3545;
  }
  .node circle.success {
    stroke: #198754;
  }
  .node circle.warning {
    stroke: #ffc107;
  }
  .node__position {
    font-size: 10px;
    color: #6B7280;
  }
  
  .node-parent__line {
    stroke: #D1D5DB;
    stroke-width: 1;
  }
  .node-parent__location {
    font-size: 10px;
    color: #6B7280;
  }
  
}