.c-annual-summary {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-200;

  &__scroll {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 1.5rem;
    padding: 0 .5rem;
    border-bottom: 1px solid $gray-200;
  }

  &__title {
    font-size: $font-size-xs;
  }

  &__quarterly {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid $gray-200;

    &:first-child {
      border-left: 1px solid $gray-200;
    }

    &:nth-child(odd) {

      .c-annual-summary__month {

        &:nth-child(odd) {
          background-color: #edeff2;
        }

      }

    }

    &:nth-child(even) {

      .c-annual-summary__month {

        &:nth-child(even) {
          background-color: #edeff2;
        }

      }

    }

    &__body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 100%;
    }

  }

  &__month {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 1 0 0%;
    width: 100%;
    &:not(:first-child) {
      border-left: 1px dashed $gray-200;
    }

    &__body {
      padding: .5rem;
    }

  }

}
