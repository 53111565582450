.l-main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));

  &__header {
    min-height: var(--main-header-height);
    display: flex;
    justify-content: space-between;
    background-color: $white;
    padding: 1rem var(--content-spacer-x);
    @include media-breakpoint-up(xl) {
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }
    @include media-breakpoint-up(full) {
      padding-top: 2rem;
    }

    &--sticky {
      position: sticky;
      top: var(--header-height);
      z-index: $zindex-sticky;
    }

    &__left {
      max-width: 100%;
      flex-grow: 1;
      .c-nav-tabs__bar {
        margin-right: calc(-1 * var(--content-spacer-x));
        margin-left: calc(-1 * var(--content-spacer-x));
        @include media-breakpoint-up(xl) {
          margin-right: 0;
          margin-left: 0;
        }
      }
      .c-nav-tabs {
        margin-right: var(--content-spacer-x);
        margin-left: var(--content-spacer-x);
        @include media-breakpoint-up(xl) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }


    &__right {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      & > * {

        &:not(:last-child) {
          margin-right: 1rem;
        }

      }

      .mat-form-field-wrapper {
        margin-bottom: 0;
      }

    }

    &__title {
      margin-bottom: .5rem;
      font-size: $h4-font-size;
      // @include media-breakpoint-up(md) {
      //   font-size: $h4-font-size;
      // }
      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size;
      }
      @include media-breakpoint-up(full) {
        font-size: $h2-font-size;
      }

      &--lg {
        font-size: $h4-font-size;
        @include media-breakpoint-up(md) {
          font-size: $h2-font-size;
        }
        @include media-breakpoint-up(xl) {
          font-size: $h1-font-size;
        }
        @include media-breakpoint-up(full) {
          font-size: map-get($display-font-sizes, 1);
        }
      }

    }

    &__title-group {
      margin-bottom: 1rem;

      @include media-breakpoint-up(xl) {
        margin-top: -.5rem;
        margin-bottom: .25rem;
      }

      @include media-breakpoint-up(full) {
        margin-bottom: .75rem;
      }

      .c-data-label__text {
        font-size: 1.25rem;
        line-height: 1.25;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: 1.5rem;
        }

      }

    }

    &__back {
      margin-right: .75rem;
      width: 1.5rem;
      flex: 0 0 1.5rem;
    }

    // &__2col {
    //   justify-content: center;
    //   .l-main {
    //     &__header {
    //       &__viewer {
    //         max-width: 100%;
    //         padding-bottom: 1.5rem;
    //         // @include media-breakpoint-up(xl) {
    //         //   max-width: $aside-2col-viewer;
    //         //   margin: 0 $aside-2col-sidebar-sm;
    //         // }
    //         @include media-breakpoint-up(xl) {
    //           max-width: $aside-2col-viewer;
    //           margin: 0 $aside-2col-sidebar-md;
    //         }
    //         @include media-breakpoint-up(xxxl) {
    //           margin: 0 $aside-2col-sidebar;
    //         }
    //       }
    //     }
    //   }
    // }

    // &--simple {
    //   @include media-breakpoint-up(xl) {
    //     padding-top: 1.5rem;
    //     padding-bottom: 1.5rem;
    //   }
    //   @include media-breakpoint-up(full) {
    //     padding-top: 2rem;
    //     padding-bottom: 2rem;
    //   }
    // }

  }

  &__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: var(--header-height);
    z-index: $zindex-sticky;
    height: var(--header-height);
    background-color: $white;
    padding: 0 var(--content-spacer-x);
    @include media-breakpoint-up(xl) {
      margin-bottom: -.5rem;
    }
    @include media-breakpoint-up(full) {
      margin-bottom: -1rem;
    }
  }

  &__filters {
    position: sticky;
    top: var(--header-height);
    z-index: $zindex-sticky;
    background-color: $body-bg;
    padding: 1rem var(--content-spacer-x);
    .l-main__aside__viewer & {
      top: calc(var(--header-height) + var(--main-header-height));
    }
  }

  &__fullwidth {
    flex-grow: 1;
    padding: 1rem var(--content-spacer-x);
  }

  &__aside {
    flex-wrap: nowrap;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    &__sidebar {
      // flex: 0 0 30rem;
      // @include media-breakpoint-down(full) {
      //   flex: 0 0 25rem;
      // }
      // @include media-breakpoint-only(xl) {
      //   flex: 0 0 22rem;
      // }


      &--left {

        @include media-breakpoint-up(xl) {
          flex: 0 22.5rem;
          max-width: 22.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          flex: 0 0 30rem;
          max-width: 30rem;
        }

        .c-sidebar {
          padding-left: var(--content-spacer-x);
        }

      }

      &--right {

        @include media-breakpoint-up(xl) {
          flex: 0 22.5rem;
          max-width: 22.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          flex: 0 0 25rem;
          max-width: 25rem;
        }

        .c-sidebar {
          padding-right: var(--content-spacer-x);
          background-color: transparent;
          border-left: 1px solid $line-dark-color;
        }

      }

      &--lite {
        @include media-breakpoint-up(xl) {
          flex: 0 16.5rem;
          max-width: 16.5rem;
        }
        @include media-breakpoint-up(xxxl) {
          flex: 0 18.5rem;
          max-width: 18.5rem;
        }
      }

    }

    &__viewer {
      min-width: 1px;

      .l-panel {
        padding: 2rem 2.5rem 1.5rem;
      }

    }

    // &__1col {

    //   .l-main {
    //     &__aside {
    //       // &__sidebar {
    //       //   display: none;
    //       // }
    //       &__viewer {
    //         max-width: 100%;
    //         padding: 0 1.5rem 1.5rem;
    //         margin: 0 auto;
    //         @include media-breakpoint-up(xl) {
    //           max-width: 57.5rem;
    //         }
    //       }
    //     }
    //   }
    // }

    // &__2col {
    //   justify-content: center;
    //   .l-main {
    //     &__aside {
    //       &__sidebar {
    //         @include media-breakpoint-up(xl) {
    //           position: sticky;
    //           top: 13.25rem;
    //         }
    //         @include media-breakpoint-down(xl) {
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;
    //         }
    //         @include media-breakpoint-only(xs) {
    //           flex-direction: column;
    //           align-items: flex-start;
    //           position: relative;
    //         }
    //         h6 {
    //           @include media-breakpoint-down(xxxl) {
    //             font-size: 1rem;
    //           }
    //         }
    //         // @include media-breakpoint-up(xl) {
    //         //   max-width: $aside-2col-sidebar-sm;
    //         // }
    //         @include media-breakpoint-up(xl) {
    //           max-width: $aside-2col-sidebar-md;
    //         }
    //         @include media-breakpoint-up(xxxl) {
    //           max-width: $aside-2col-sidebar;
    //         }
    //       }
    //       &__viewer {
    //         max-width: 100%;
    //         padding: 0 0 1.5rem;
    //         @include media-breakpoint-up(xl) {
    //           max-width: calc(#{$aside-2col-viewer} + #{$aside-2col-sidebar-md});
    //           // margin-right: $aside-2col-sidebar-md;
    //           // margin-right: 0;
    //         }
    //         // @include media-breakpoint-up(xl) {
    //         //   margin-right: $aside-2col-sidebar-sm;
    //         // }
    //         // @include media-breakpoint-up(xl) {
    //         // }
    //         @include media-breakpoint-up(xxxl) {
    //           // margin-right: $aside-2col-sidebar;
    //           max-width: calc(#{$aside-2col-viewer} + #{$aside-2col-sidebar});
    //         }
    //       }
    //     }
    //   }
    // }

  }

  &__left-nav {

    &__sidebar {
      min-width: 1px;
      flex: 0 0 auto;
      align-self: flex-start;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      @include media-breakpoint-up(md) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-right: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        flex: 0 0 15rem;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-right: 2.5rem;
      }
      @include media-breakpoint-up(xxl) {
        position: sticky;
        top: calc(var(--header-height) * 2);
        z-index: $zindex-sticky;
      }
    }

    &__content {
      min-width: 1px;
      padding: 2rem 0;
      @include media-breakpoint-up(md) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }
    }

  }

  &__footer__text-outline {
    overflow: hidden;
    margin-top: 1.5rem;
    width: calc(100% + var(--content-spacer-x));

    .text-outline {
      font-size: 9.5vw;
      line-height: 1;
      opacity: 1;
      transform: translateY(2vw);
    }

  }

}
