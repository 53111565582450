.c-select-timeline {

  &:hover {
    .c-select-timeline {

      &__more {
        opacity: 1;
        transform: translateY(0);
      }

      &__title {
        .text-outline {
          opacity: 1;
          color: $primary;
        }
      }

    }

  }

  &__title {
    margin-bottom: -2.75rem;
    .text-outline {
      font-size: 5.5rem;
      transition: color .2s ease-in-out, opacity .2s ease-in-out;
    }
  }

  &__content {
    position: relative;
  }

  &__more {
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: 1.5rem;
    font-size: $font-size-sm;
    opacity: 0;
    transform: translateY(100%);
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
  }

  &__list {
    position: relative;
    display: flex;
    padding: 0 1.5rem;
    background-color: $body-bg;
    border-top: 1px solid $primary;
    @include media-breakpoint-up(xl) {
      padding: 0 2rem;
    }
    @include media-breakpoint-up(full) {
      padding: 0 3rem;
    }
  }

  &__item {
    margin-top: -1rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
      @include media-breakpoint-up(xl) {
        margin-right: 2rem;
      }
      @include media-breakpoint-up(full) {
        margin-right: 3rem;
      }
    }
  }

}
