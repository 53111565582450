.c-analytic {
    &-item {
        padding-bottom: 1.5rem;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $border-color;
        }
        &__name {
            display: flex;
            align-items: center;
            @include media-breakpoint-up(lg) {
                font-size: 1.125rem;
            }
        }
        &__description {
            font-size: 0.875rem;
            opacity: .5;
            margin: .5rem 0 1rem;
        }
        .mat-form-field {
            @include media-breakpoint-up(sm) {
                width: 17.5rem;
            }
        }
        .mat-form-field-wrapper {
            margin-bottom: 0;
        }
    }
}