.c-card-lite {
  position: relative;
  background-color: $card-bg;
  padding: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border: $card-border-width solid rgba($black, .1);
    transition: border-color .2s ease-in-out;
  }

  &:hover {
    &::before {
      border-color: $black;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

}
