@use "sass:math";

.mat-form-field {
  &-appearance-fill {
    width: 100%;

    .mat-form-field {
      &-wrapper {
        padding-bottom: 0 !important;
        margin-bottom: 1rem;
      }

      &-flex {
        align-items: center;
        border: $mat-input-border-width solid $mat-input-border-color;
        border-radius: $mat-input-border-radius !important;
        padding: $mat-input-padding-y $mat-input-padding-x !important;
        background-color: $mat-input-bg !important;

        &:hover {
          border-color: $primary;
        }
      }

      &-label-wrapper {
        font-size: $mat-input-font-size;
      }

      &-infix {
        padding: 0 !important;
        border-top-width: $mat-input-border-top-width;

        .mat-input-element,
        .mat-select-value-text {
          font-size: $mat-input-font-size;
        }

        .mat-select-arrow {
          margin-top: .375rem;
          margin-right: 0;
        }
      }

      &-underline {
        display: none !important;
      }
    }
  }

  // Icon
  &__icon-right {
    .mat-form-field {
      &-flex {
        .btn-icon {
          position: absolute;
          top: 50%;
          right: -(math.div($mat-input-padding-x-lg, 2));
          transform: translateY(-50%);
          margin-top: -.375rem;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  &__icon-left {
    .mat-form-field {
      &-infix {
        padding-left: $mat-input-padding-x !important;

        &>.icon {
          position: absolute;
          top: 50%;
          left: -(math.div($mat-input-padding-x-lg, 2));
          transform: translateY(-50%);
          margin-top: -.375rem;

          &:focus {
            box-shadow: none;
          }
        }
      }

      &-label-wrapper {
        left: $mat-input-padding-x !important;
      }
    }
  }

  &-nofloating {
    .mat-form-field-infix {
      border-top: 0;

      &>.icon {
        margin-top: 0;
      }
    }

    .mat-form-field-suffix {
      font-size: 0.625rem;
      opacity: .4;
    }

    .mat-form-field-flex {
      min-height: 2.5rem;
      display: flex;
      align-items: center;
    }

    .mat-form-field-label-wrapper {
      top: -0.5rem;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0) !important;

      .mat-select-arrow {
        margin-top: 0;
      }
    }

    &.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label {
        opacity: 0;
        transform: none;
      }
    }
  }

  &-subscript-wrapper {
    font-size: 0.625rem !important;
  }

  &-hint-end {
    margin-top: .813rem;
  }

  // Size
  &__lg {
    .mat-form-field {
      &-flex {
        min-height: 3rem;
        padding: $mat-input-padding-y-lg $mat-input-padding-x-lg !important;
      }

      &-label-wrapper {
        font-size: $mat-input-font-size-lg;
      }

      &-infix {
        // padding: 0 !important;
        border-top-width: $mat-input-border-top-width;

        .mat-input-element,
        .mat-select-value-text {
          font-size: $mat-input-font-size-lg;
        }
      }
    }
  }

  &__sm {
    .mat-form-field {
      &-flex {
        padding: .25rem .5rem !important;
        min-height: 2rem;
      }

      &-label-wrapper {
        font-size: $mat-input-font-size-sm;
      }
    }
  }

  // Theme
  &__dark {
    .mat-form-field {
      &-flex {
        border-color: $white !important;
        background-color: $primary !important;

        &:hover {
          border-color: $white;
        }
      }

      &-infix {
        .mat-input-element {
          caret-color: $white;
        }

        .mat-select-value-text,
        .mat-select-arrow {
          color: $white;
        }
      }

      &-label {
        color: $white !important;
      }
    }
  }

  // &-textarea {
  //   .mat-form-field-flex {
  //     height: 6.5rem;
  //     .mat-form-field-infix,
  //     textarea.mat-input-element {
  //       height: 100%;
  //     }
  //   }
  // }

}

file-upload {
  padding: 0 !important;
  background-color: transparent !important;
  min-height: auto !important;
  outline-offset: 0 !important;
  outline: none !important;
  margin-bottom: 1rem;

  // .upload-text {
  //     padding-left: 0 !important;
  //     text-align: center;
  // }
  .upload-input {
    color: $gray-400 !important;
    padding: 0 1.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    min-height: 6rem;
    border: 1px dashed $gray-400;
    margin-bottom: 1rem;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    // &:hover {
    //     color: $gray-600 !important;
    // }

    .icon {
      margin-right: .5rem;
    }
  }

  &:hover {
    .upload-input {
      color: $gray-600 !important;
      border-color: $body-color;
    }
  }

  &.list-visible {
    .upload-input {
      margin-left: 0 !important;
      margin-bottom: 1rem;
    }
  }
}

.mat-checkbox {
  &-label {
    font-size: 0.875rem;
  }

  &-frame {
    border-width: 1px !important;
  }
}

// Custom option list
.mat-option-avatar {
  .mat-option-text {
    display: flex;
    align-items: center;

    .c-avatar {
      margin-right: 1rem;
    }
  }
}

/**
*  Custom colors
*/

@each $color, $value in $badge-colors {

  .mat-form-field {
    &.#{$color} {

      .mat-form-field-flex {
        background-color: lighten($value, 30%) !important;
      }

    }

  }

  .mat-option {
    &.#{$color} {
      background-color: lighten($value, 30%) !important;
    }
  }

}
