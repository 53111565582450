.c-badge-group {
  display: flex;

  .badge {
    &:not(:first-child) {
      margin-left: .5rem;
    }
  }

}
