.l-admin-header {
    //background-color: $gray-200;
    padding: 1rem 0;
    &__right {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        justify-content: flex-end;
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}