.c-type-card {
  position: relative;

  &__wrapper {
    position: relative;
    padding: 2rem 1.5rem;
    background-color: $white;
    border: 1px solid $line-dark-color;
    transition: border-color .2s ease-in-out;
    @include media-breakpoint-up(xl) {
      padding: 2.5rem 2rem;
    }
    @include media-breakpoint-up(full) {
      padding: 3rem 2.5rem;
    }
  }

  &__text-outline {
    margin-bottom: -2rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: -2.25rem;
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: -2.875rem;
    }
    @include media-breakpoint-up(fullMax) {
      margin-bottom: -3.375rem;
    }
    .text-outline {
      font-size: 3.5rem;
      transition: color .2s ease-in-out, opacity .2s ease-in-out;
      @include media-breakpoint-up(xl) {
        font-size: 4rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 5rem;
      }
      @include media-breakpoint-up(fullMax) {
        font-size: 6rem;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 2.5rem;
    .mat-slide-toggle {
      margin-left: auto;
    }
  }

  &__body {
    margin-bottom: 3rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__more {
    font-size: $font-size-sm;
  }

  &:hover,
  &.active {
    .c-type-card {

      &__wrapper {
        border-color: $primary;
      }

      &__text-outline {
        .text-outline {
          opacity: 1;
        }
      }

    }
  }

  &.active {
    .c-type-card {

      &__text-outline {
        .text-outline {
          color: $primary;
        }
      }

    }
  }

}
