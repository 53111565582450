$add-repeat-gutter-width: 1rem;

.c-add-repeat {

  &__bar {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: $add-repeat-gutter-width;
    margin-bottom: .5rem;

    &__col {
      display: flex;
      min-width: 1px;

      &--user {
        flex: 1 0 0%;
      }

      &--select {
        flex: 0 0 11rem;
      }

      &--actions {
        flex: 0 0 6rem;

        .btn {
          width: 100%;
        }

      }

    }

  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: -.5rem;

    &__item {
      margin-top: .5rem;
    }

  }

  .mat-form-field-wrapper {
    margin-bottom: 0;
  }

}

.c-card-add-user {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: $add-repeat-gutter-width;
  background-color: $white;
  border: 1px solid rgba($black, .1);
  padding: .75rem 0;
  transition: border-color .2s ease-in-out;

  @include media-breakpoint-up(lg) {
    padding: 1rem 0;
  }

  &:hover {
    border-color: $black;
  }

  &__col {
    display: flex;
    min-width: 1px;

    &--user {
      flex: 1 0 0%;
      padding-left: 1rem;
    }

    &--select {
      flex: 0 0 11rem;
    }

    &--actions {
      flex: 0 0 6rem;
      justify-content: flex-end;
      padding-right: .5rem;

      .btn {
        &:not(:last-child) {
          margin-right: .25rem;
        }
      }

    }

  }

  .mat-form-field-wrapper {
    margin-bottom: 0;
  }

}
