.c-document {
    display: flex;
    align-items: center;
    &-cnt {
        padding-left: 1rem;
        padding-right: 1rem;
        flex: 1;
        min-width: 0;
    }
    // &-icon {
    //     width: 2.5rem;
    //     height: 2.5rem;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border: 1px solid $gray-300;
    //     background-color: $gray-100;
    // }
    &-position {
        font-size: 0.75rem;
        color: $gray-400;
        line-height: 1;
    }
    &-name {
        font-size: 0.875rem;
        cursor: pointer;
        line-height: 1;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-actions {
      margin-left: auto;
    }

    // List
    &-list {
      .c-document {
        margin-bottom: 1rem;
      }
    }
}
