.l-calendar {
  padding-top: 0.3rem;
  &__header {
    display: flex;
    align-items: center;
    margin-top: -0.6rem;
    padding-bottom: 1rem;

    &__start {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      width: 33.3333333333%;

      & > * {
        margin-right: 1rem;
      }

    }

    &__half {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    &__end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    &__title {
      font-size: 1.25rem;
      margin-bottom: 0;
    }

  }

  &__nav {
    display: flex;
    align-items: center;
    margin-right: .5rem;

    .btn {
      width: 2rem;
      flex: 0 0 2rem;
    }

  }

}
