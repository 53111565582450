.mat-chip {

  &-list {

    &-wrapper {
      input.mat-input-element,
      .mat-standard-chip {
          font-size: 0.813rem;
          line-height: 1;
      }
    }
  }

  .c-avatar {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    margin-right: .5rem;
    margin-left: -0.3125rem;
  }

  &.mat-standard-chip {
    // padding: 0.25rem 0.5rem 0.25rem 0.25rem !important;
    background-color: $gray-200 !important;
    &:hover {
        background-color: $gray-200 !important;
    }
    .mat-chip-remove {
        opacity: 1 !important;
    }
  }

}


.mat-chip-list {

  &-wrapper {

    input.mat-input-element,
    .mat-standard-chip {
      margin: 3px !important;
    }

  }
}
