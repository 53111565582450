.c-support {
    &__header {
        .c-avatar {
            &:before {
                content: none;
            }
        }
        .c-data-label {
            &__caption {
                font-size: .75rem;
                color: $gray-400;
            }
        }
    }
    &__content {
        p {
            font-size: 0.875rem;
        }
    }
    &__item {
        border: 1px solid $border-color;
        padding: 0.875rem 1.25rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 0.625rem;
        }
        &:hover {
            border-color: $primary;
        }
        .icon {
            margin-right: 1rem;
        }
        .c-data-label__caption {
            font-size: .75rem;
            color: $gray-400;
            overflow: inherit;
            text-overflow: inherit;
            white-space: inherit;
        }
    }
}