.c-toggle-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid $primary;
  transition: background-color .2s ease-in-out;
  background-color: $white;
  .icon {
    stroke: $primary;
    width: .875rem;
    height: .875rem;
    flex: 0 0 .875rem;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  //
  // Status
  //

  &--completed {
    background-color: $success;
    .icon {
      opacity: 1;
    }
  }
  &--inprogress {
    background-color: $warning;
  }

  //
  // Sizes
  //

  &__lg {
    height: 2rem;
    width: 2rem;
  }

  &__sm {
    height: 1.25rem;
    width: 1.25rem;
  }
}

