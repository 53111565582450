.c-icon-badge {
  position: relative;

  .badge {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    border-radius: 1rem;
    padding: .125rem;
    min-width: 1rem;
  }

}
