.l-2cols-right {

  &__content {
    @include media-breakpoint-up(full) {
      padding-right: 3.5rem;
    }
    @include media-breakpoint-between(xxl,full) {
      padding-right: 2.5rem;
    }
    @include media-breakpoint-down(xxl) {
      padding-right: 2rem;
    }
  }

  &__sidebar {
    overflow: hidden;
    @include media-breakpoint-up(xxl) {
      flex: 0 0 25rem;
    }
    @include media-breakpoint-down(xxl) {
      flex: 0 0 22rem;
    }
  }

}

