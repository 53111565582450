.c-board-view {
  height: 830px;
  margin-top: -1rem;

  &__scrollable {
    height: 100%;
    min-width: 1px;
    overflow-x: auto;
  }

  &__body {
    height: 100%;
  }

  &__container {
    height: 100%;
  }

  &__columns {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -.5rem;

    &__item {
      height: 100%;
      padding: 0 .5rem;
      flex: 0 0 26rem;
      max-width: 26rem;
      width: 26rem;
    }

  }

  // Col
  &__col {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      .c-board-view__col__title {
        .text-outline {
          opacity: 1;
          color: $primary;
        }
      }
    }

    &__scrollable {
      height: 100%;
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      // margin: -2px -12px 0;
      min-height: 1px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    // &__header {

    // }

    &__title {
      margin-bottom: -2.5rem;
      .text-outline {
        font-size: 5rem;
        transition: color .2s ease-in-out, opacity .2s ease-in-out;
      }
    }

    &__body {
      height: 100%;
      min-height: 0;
      position: relative;
      background-color: $body-bg;
    }

  }

  &__card {
    margin-bottom: 1rem;
  }

}
