.c-card-large {
  position: relative;
  background-color: $card-bg;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    border: $card-border-width solid rgba($black, .1);
    transition: border-color .2s ease-in-out;
  }

  &:hover {
    &::before {
      border-color: $black;
    }
  }

  &__media {
    background-color: $primary;

    &__img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
    }

    &__header {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
    }

    &__footer {
      margin-top: auto;
    }

  }

  &__body {
    padding: 1.5rem;

    &__description {
      font-size: $font-size-sm;
    }

  }

  &__subtitle {
    font-size: $font-size-sm;
    font-family: $font-family-condensed;
    text-transform: uppercase;
    color: $data-label-color;
  }

  &__footer {
    padding: 0 1.5rem 1.5rem;
  }

}
