.c-admin-detail-block {
    margin-bottom: 1.5rem;
    label {
        font-size: 0.875rem;
        margin-bottom: .25rem;
    }
    &__title {
        border-bottom: 1px solid $border-color;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
    }
}