.state-badge {
  display: inline-block;
  font-family: $state-badge-font-family;
  font-weight: $state-badge-font-weight;
  line-height: $state-badge-line-height;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $state-badge-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $state-badge-border-width solid $state-badge-border-color;
  @include button-size($state-badge-padding-y, $state-badge-padding-x, $state-badge-font-size, $state-badge-border-radius);
  @include transition($state-badge-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  &:active,
  &.active {
    @include box-shadow($state-badge-active-box-shadow);

    &:focus {
      @include box-shadow($state-badge-focus-box-shadow, $state-badge-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $state-badge-disabled-opacity;
    @include box-shadow(none);
  }
}

//
// Alternate state badge
//

@each $color, $value in $theme-colors {
  .state-badge-#{$color} {
    @include state-badge-variant($value, $value);
  }
}

//
// State badge Sizes
//

.state-badge-lg {
  @include button-size($state-badge-padding-y-lg, $state-badge-padding-x-lg, $state-badge-font-size-lg, $state-badge-border-radius-lg);
}

.state-badge-sm {
  @include button-size($state-badge-padding-y-sm, $state-badge-padding-x-sm, $state-badge-font-size-sm, $state-badge-border-radius-sm);
}
