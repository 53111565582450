.c-card-view-short {
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid rgba($black, .1);
  padding: 1rem;

  @include media-breakpoint-down(xxl) {
    padding: .5rem;
  }

  &:hover {
    border-color: $black;
    cursor: pointer;
  }

  &__col {
    min-width: 1px;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:first-child {
      flex: 1 0 0%;

      .c-data-label__text {
        line-height: 1.25;
      }

    }

    &--team {

      .c-data-label__caption {
        margin-bottom: .125rem;
      }

    }

  }

}
