.c-img-gallery {

  // Mat Button
  &.mat-button-toggle {
    display: flex;
    &-appearance-standard {
      .mat-button-toggle-label-content {
        padding: 0;
        display: block;
      }
    }
  }

  &__wrapper {

  }

  &__media {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__check {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  &__grid {
    border-radius: 0 !important;
    border: none !important;
    display: block !important;
  }

}
