.c-havas-login {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    background-color: $havas-login-bg;
    width: 100%;
    max-width: 300px;
    text-align: left;
    padding: 0 0 0 48px;
    border-radius: 3px;
    overflow:hidden;
    border: none;
    box-shadow: $havas-login-box-shadow;
    cursor: pointer;
    margin: 20px 0;
    &-iso {
        width: 48px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $havas-login-iso-bg;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            width: 4px;
            height: 6px;
            background-color: $havas-login-bg;
            transform: translateX(-50%);
        }
        &:before {
            bottom: 50%;
            margin-bottom: 1px;
        }
        &:after {
            top: 50%;
            margin-top:1px;
        }
    }
    &-logo {
        display: flex;
        margin-left: 20px;
        svg {
            width: 144px;
            height: 16px;
            fill: $havas-login-logo-color;
            @include media-breakpoint-down (sm){
                width: 120px;
            }
        }
    }
}
