.c-no-results {
  display: flex;

  &__wrapper {
    margin: auto;
    text-align: center;
  }

  &__emoji {
    font-size: 5rem;
    color: $gray-300;
    margin-bottom: 1.5rem;
  }

  &__title {

  }

  &__description {
    color: $gray-500;
    margin-bottom: 0;
  }

  &--sm {

    .c-no-results {

      &__emoji {
        font-size: 1.5rem;
        margin-bottom: .5rem;
      }

      &__title {
        font-size: 1rem;
        margin-bottom: 0;
      }

      &__description {
        font-size: .875rem;
      }

    }

  }

}
