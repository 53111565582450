.c-overview-view {

  &__left {
    // margin-bottom: 3rem;
    &__wrapper {
      @include media-breakpoint-up(xl) {
        padding-right: 3rem;
        border-right: 1px solid $line-dark-color;
      }
    }
  }

  &__right {
    // margin-bottom: 3rem;
    @include media-breakpoint-up(xl) {
      width: 34.5rem;
    }
    &__wrapper {
      // height: 100%;
      position: relative;
      overflow-x: hidden;
      width: calc(100% + 2rem);
      padding-right: 2rem;
      // padding-left: 3rem;
      @include media-breakpoint-up(xxl) {
        width: calc(100% + 2.5rem);
        padding-right: 2.5rem;
        padding-left: 3rem;
      }
      @include media-breakpoint-up(full) {
        width: calc(100% + 5rem);
        padding-right: 5rem;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   bottom: 0;
      //   left: 0;
      //   width: 3rem;
      //   z-index: 1;
      //   pointer-events: none;
      //   @include gradient-x($body-bg, transparent);
      // }
      // & > .row {
      //   height: 100%;
      //   & > .col {
      //     display: flex;
      //     margin: auto 0;
      //     &:first-child {
      //       margin-top: 0;
      //     }
      //     &:last-child {
      //       margin-bottom: 0;
      //     }
      //   }
      // }
    }

  }

}
