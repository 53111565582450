.l-detail {
  min-height: var(--main-header-height);

  .l-main {

    &__header {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      &__left {
        display: flex;
        align-items: center;

        .c-avatar {
          margin-right: 1rem;
        }

        .badge {
          margin-bottom: .25rem;
        }

      }

      &__right {
        padding-bottom: 0;
      }

      &__title {
        margin-bottom: 0;
      }

    }

  }

}
