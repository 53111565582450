.mat-slide-toggle {
  height: 20px !important;
  line-height: 20px !important;

  &-bar {
    height: 20px !important;
    width: 40px !important;
    border-radius: 10px !important;
    background-color: $white !important;
    border: 1px solid $primary;
  }

  &-thumb {
    border: 1px solid $primary;
    box-shadow: none !important;
    &-container {
      top: -1px !important;
      left: -1px !important;
    }
  }

  &-content {
    font-size: $input-font-size;
  }

  &.mat-checked {
    .mat-slide-toggle {

      &-thumb {
        &-container {
          transform: translate3d(20px, 0, 0) !important;
        }
      }

    }
  }

  // Theme

  &.mat-primary.mat-checked {
    .mat-slide-toggle {

      &-bar {
        background-color: $primary !important;
      }

      &-thumb {
        background-color: $white !important;
      }

    }
  }

}
