.header {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  color: $white;
  padding: 0 var(--content-spacer-x);
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;

  &__right {
    display: flex;
    margin-left: auto;

    & > * {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

  }

  &__ham {
    margin-right: 1rem;
    margin-left: -.5rem;
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__brand {
    img {
      height: 2rem;
      @include media-breakpoint-up(xl) {
        height: 2.5rem;
      }
    }
  }

  &__nav {
    font-family: $font-family-condensed;
    margin-right: auto;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1.5rem;
      @include media-breakpoint-up(md) {
        margin-left: 2.5rem;
      }
    }
    li {
      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }
    a {
      color: $gray-400;
      text-decoration: none;
      text-transform: uppercase;
      transition: color .2s ease-in-out;

      &:hover,
      &:focus,
      &.active {
        color: $white;
      }

    }
  }

  &__search {
    margin-left: auto;
    width: 13rem;
    background-color: transparent;
    border-color: $white;
    color: $white;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    .mat-form-field-wrapper {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    .btn {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }

    &__search {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

  }

  &__translations {
    margin-left: auto;
    background-color: transparent;
    border-color: $white;
    color: $white;
    .mat-form-field-wrapper {
      margin-bottom: 0;
    }
    .mat-select-value{
      color: $white;
    }
    padding-right: 1rem;
  }
}
