.c-box-send {
  background-color: $line-dark-color;
  @include media-breakpoint-up(xxl) {
    padding: 2rem 2.5rem 2.5rem;
  }
  @include media-breakpoint-down(xxl) {
    padding: 2rem;
  }
  &__actions {
    .btn:not(:last-child) {
      margin-right: 1rem;
    }
  }

}
