.c-menu-profile {
    min-width: 12.5rem !important;
    &-header {
      padding: 1rem .5rem;
      border-bottom: 1px solid $line-dark-color;
    }
    &-cnt {
        padding: .5rem;
    }
    &-footer {
        border-top: 1px solid $line-dark-color;
        padding: .5rem .5rem 0;
    }
    .mat-menu {
        &-content {
            padding: .5rem;
        }
        &-item {
           padding: 0;
           font-size: 0.875rem;
           height: 2.25rem !important;
           line-height: 2.25rem !important;
           &:hover:not([disabled]) {
               background-color: transparent !important;
           }
        }
    }
    .btn-logout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $danger;
    }
}
