.badge {
  font-family: $font-family-condensed;
  color: $dark;
  border: $badge-border-width solid $dark;

  &--sm {
    padding: .25rem .5rem !important;
    font-size: .75rem !important;
  }
  &--xs {
    padding: .1875rem .5rem !important;
    font-size: .625rem !important;
    letter-spacing: .0313rem;
  }

}

@each $color, $value in $badge-colors {
  .badge--#{$color} {
    background-color: $value;
    border-color: $value;

    // @if $color == 'dark' or 'havas' or 'client' {
    //   color: $white;
    // }

    @if $color == 'dark' or $color == 'havas' or $color == 'client' {
      color: $white !important;
    }

  }
}

@each $color, $value in $badge-colors {
  .badge-outline--#{$color} {
    background-color: transparent;
    border-color: $value;
  }
}
