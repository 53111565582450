.mat-datepicker {

  &-toggle {

    &__custom-icon {
      .mat-icon-button {
        margin-top: -.25rem;
        height: 1.5rem !important;
        width: 1.5rem !important;
      }
      .mat-button-focus-overlay {
        display: none;
      }
    }

  }

}
