@mixin avatar-variant($size, $padding, $font-size, $border-width) {
  height: $size;
  width: $size;
  padding: $padding;
  font-size: $font-size;

  &::before {
    border-width: $border-width;
  }

}

.c-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $avatar-size;
  width: $avatar-size;
  border: none;
  background-color: $avatar-bg;
  color: $avatar-color;
  padding: $avatar-padding;
  font-family: $avatar-font-family;
  font-size: $avatar-font-size;
  font-weight: $avatar-font-weight;
  line-height: $avatar-line-height;
  border-radius: $avatar-border-radius;

  &,
  &::before,
  &__wrap,
  img {
    border-radius: $avatar-border-radius;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border: $avatar-border-width solid $avatar-inner-bg;
    transition: border-color .2s ease-in-out;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: $avatar-inner-bg;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  // Theme
  &--dark {
    background-color: $primary;
    &::before {
      border-color: $white;
    }
  }

  // Size
  &--xl {
    @include avatar-variant($avatar-size-xl, $avatar-padding-xl, $avatar-font-size-xl, $avatar-border-width * 1.25);
  }

  &--lg {
    @include avatar-variant($avatar-size-lg, $avatar-padding-lg, $avatar-font-size-lg, $avatar-border-width * 1.25);
  }

  &--sm {
    @include avatar-variant($avatar-size-sm, $avatar-padding-sm, $avatar-font-size-sm, $avatar-border-width * 0.75);
  }

  &--xs {
    @include avatar-variant($avatar-size-xs, $avatar-padding-xs, $avatar-font-size-xs, $avatar-border-width * 0.5);
  }

}

@each $color, $value in $theme-colors {
  .c-avatar--#{$color} {
    &::before {
      border-color: $value;
    }
  }
}

.c-multiple-avatar {
  display: flex;

  .c-avatar {

    &--xs {
      &:not(:first-child) {
        margin-left: -0.5rem;
      }
    }

    &--sm {
      &:not(:first-child) {
        margin-left: -0.75rem;
      }
    }


  }

}

