.c-admin-table {
    //width: 100%;
    font-size: 0.875rem;
    //height: 400px;
    width: 100%;
    overflow: auto;
    margin-bottom: 2.5rem;
    .mat-table {
        background-color: transparent !important;
        width: 100%;
        thead {
            background-color: $body-bg;
        }
    }
    th.mat-header-cell {
        font-weight: $font-weight-bold;
        border-bottom-width: 2px;
    }
    td {
        font-size: 0.875rem;
    }
    tr.mat-row:hover {
        background-color: rgba($white, .3) !important;
    }
    .btn-icon-only {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .mat-column-actions {
        width: auto;
        padding-right: .5rem !important;
    }
    &-filter {
        margin-top: .25rem;
        .mat-form-field-nofloating {
            .mat-form-field-flex {
                padding: .25rem .5rem!important;
                height: 2rem;
            }
            .mat-form-field-wrapper {
                margin-bottom: .75rem;
            }
            .mat-form-field-infix {
                width: 100%;
            }
        }
    }
    th.mat-header-cell, 
    td.mat-cell, 
    td.mat-footer-cell {
        padding: 0 .25rem;
        white-space: nowrap;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: .5rem;
        }
    }

    // th.mat-header-cell {
    //     white-space: nowrap;
    // }
}