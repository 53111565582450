.c-accordion {
  display: block;
  margin-top: -.5rem;

  &__item {
    display: block;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: .5rem 0;
      border-bottom: 1px solid $line-dark-color;

      &.show {
        .c-accordion__item__toggle {
          .icon {
            transform: rotate(-180deg);
          }
        }
      }

    }

    &__title {
      font-size: 1.25rem;
      margin-bottom: 0;
    }

    &__toggle {
      margin-left: auto;
      .icon {
        margin-left: .5rem;
        transition: transform .2s ease-in-out;
      }
    }

    &__body {
      padding: 1rem 0;
    }

  }

}
